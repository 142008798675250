<template>
    <div class="policy">
        <h2><strong>Privacy Policy</strong></h2>
        <p>Last updated: October 21st, 2024</p>
        <h3><strong>Overview of Privacy Policy</strong></h3>
        <p>
            Welcome to our service, the service is provided and controlled by SMART-PLUS PTE. LTD. (hereinafter referred to as 'SMART-PLUS', 'us' or
            'we'). By using the service, you agree to this Privacy Policy.
        </p>
        <p>The service includes:</p>
        <ul class="policy-ul">
            <li>
                Our website and user accounts that may be accessed at *cloud.akuvox.com, *cloud.akubela.com (*cloud include ecloud, ucloud, jcloud,
                scloud and so on), and all associated sites connected with it, excluding any third parties’ sites (the ‘Portal’);
            </li>
            <li>Online services accessible through the Website ('Web Services');</li>
            <li>Software applications that can be downloaded to a mobile device ('Mobile Apps'), such as Akuvox SmartPlus, BelaHome, and so on;</li>
            <li>
                Related products ('Devices') of the service, including, but not limited to, door phones, indoor monitors, android video phones, access
                control terminals, and home control panels;
            </li>
            <li>Any services available on the Website and the Mobile Apps ('Available Services').</li>
            <li>The term 'SERVICE' means the Website, Web Services, Mobile Apps, Devices and Available Services.</li>
        </ul>
        <p>
            The privacy policy describes who we are, what information we collect about you, how we use and disclose your personal information, the
            details of how we protect your information, and your data privacy rights. Our SERVICE takes your personal information very seriously and
            gives you control over your personal information. We use robust encryption technology to protect your personal data and privacy, and
            employ strict policies to manage all data. We will always protect personal data concerning you in accordance with the so-called General
            Data Protection Regulation ("GDPR") and California Consumer Privacy Act ("CCPA").
        </p>
        <h3><strong>A. Who we are</strong></h3>
        <p>
            The SERVICE is provided and controlled by SMART-PLUS PTE. LTD. a.s. with the Tax Id. No 202116626G and the registered office in 6 RAFFLES
            QUAY #14-06, Singapore (048580).
        </p>
        <h3><strong>B. What Personal Information We Collect</strong></h3>
        <p>Personal information is collected by our SERVICE for the purpose of providing services under applicable laws.</p>
        <p>
            "Personal information" shall have the same meaning as "personal data" and shall include any information relating to an identified or
            identifiable natural person ("data subject"); an identifiable person is one who can be identified, directly or indirectly, in particular
            by reference to an identification number or to one or more factors specific to his/her physical, physiological, mental, economic, cultural
            or social identity.
        </p>
        <p>
            We will ask you to provide personal information that is necessary to provide the SERVICE to you. If you do not provide us with any or all
            of these information, we will not process them, however, the SERVICE or some of its function might be limited.
        </p>
        <p>In the following part, we want you to understand the types of information we will collect when you use our SERVICE.</p>
        <p><strong>1. User Account Registration</strong></p>
        <p>To create your SERVICE account, you need to provide the following Personal Information in course of the registration process:</p>
        <p>Property Manager Account：</p>
        <ul class="policy-ul">
            <li>Name</li>
            <li>Surname</li>
            <li>E-mail address</li>
        </ul>
        <p>End User Accout：</p>
        <ul class="policy-ul">
            <li>Name</li>
            <li>Surname</li>
            <li>E-mail address</li>
            <li>Mobile phone number</li>
            <li>Address (If you are a residential user, we will collect your residential address; if you are a business user, we will collect your business address. )
            </li>
        </ul>
        <p><strong>2. Use of the SERVICE</strong></p>
        <p>During your use of our SERVICE, we will collect the Personal Information below (or only some of them).</p>
        <ul class="policy-ul">
            <li>Mobile phone IP address</li>
            <li>Mobile phone Bluetooth information</li>
            <li>Device name</li>
            <li>Device IP address and Mac address</li>
            <li>Device and mobile phone location</li>
            <li>Authentication information</li>
            <li>Images, videos and audio processed via Mobile Apps and stored in the cloudo</li>
            <li>Configuration changes of devices and systems</li>
            <li>Log information, especially call logs, snapshots of door opening, door open histories, and automation histories</li>
            <li>Payment status</li>
        </ul>
        <p><strong>3. Service of Troubleshooting and Improvement</strong></p>
        <p>
            To solve the issues you encounter while using the SERVICE and to optimize our SERVICE, we will collect the following information while you
            use our Mobile Apps.
        </p>
        <ul class="policy-ul">
            <li>Last login</li>
            <li>Duration of use</li>
            <li>Audio and video calls</li>
            <li>System logs</li>
            <li>Installed applications Data</li>
            <li>Configurations download, in case they contain personal information, e.g. contact names</li>
        </ul>
        <p><strong>4. Subscription of the SERVICE</strong></p>
        <p>If you have selected to pay for the SERVICE, the following personal details will be provided to us.</p>
        <ul class="policy-ul">
            <li>Name</li>
            <li>Surname</li>
            <li>E-mail address</li>
            <li>Country of residence</li>
            <li>Payment details</li>
        </ul>
        <p>
            The processing of these personal data is required to provide you with the SERVICE and is necessary for the execution of the contract we
            have with you.
        </p>
        <p><strong>5. Third-Party Services</strong></p>
        <p>
            When you use a third-party service via our SERVICE, we process personal information to respond to your requests, provide the requested
            service to you, and improve our SERVICE. As part of the provision of the SERVICE and to provide you with tailored content, we will,
            through our agreements with these third-party services, receive personal information for identifying and managing users.
        </p>
        <p>
            To offer you the social share feature, we have integrated with MobTech ShareSDK, which will collect your personal information such as
            system information, network status, IMEI, OAID, IMSI, app list, mobile phone base station, social platform OpenID, and your mobile phone
            geographic location. For the details, please visit www.mob.com/about/policy for the related privacy policies and terms.
        </p>
        <p>
            Please note that your use of third-party services is subject to these third-party terms of use or service and their respective privacy
            notices. We encourage you to read these third-party terms carefully before you enable them on your Devices. Our processing of your
            personal information is necessary for the performance of our agreement with you, as well as to serve our legitimate interest.
        </p>
        <p>
            And we may also collect information about you from third-party services. The processing of these personal data is required to provide you
            with the SERVICE and therefore necessary for the performance of our contract with you.
        </p>
        <p>
            Important: you may object to this data collection at any time (for any reason or convenience). In that case, we will stop the personal
            data collection for this purpose immediately.
        </p>
        <h3><strong>C. Cookies and Similar Technologies</strong></h3>
        <p>
            Cookies and similar technologies are commonly used technologies on the Internet. They are small files containing strings of characters
            sent to your computer when you visit a website. When you revisit a website, cookies enable the website to recognize your browser. Cookies
            can also store user preferences and other information. You can reset your browser to reject all cookies or alert you when a website is
            sending cookies to your computer. But without cookies, some website features or services may not work correctly.
        </p>
        <p>
            When you access and use the Website and Mobile Apps, we may use these technologies to send cookies or anonymous identifiers to your device
            to collect and store information. We treat information collected through cookies and other technologies as non-personal information,
            unless your local laws treat Internet Protocol (IP) addresses or similar identifying marks as personal information. We use cookies and
            similar technologies for the following services:
        </p>
        <p><strong>1. To ensure the safe and efficient operation of our SERVICE</strong></p>
        <p>
            We may set authentication or security cookies or anonymous identifiers that help us confirm that you are securely logged into the service
            or assess whether you may encounter illegal acts such as fraud. These technologies will also help us improve service efficiency and the
            system login and response speed.
        </p>
        <p><strong>2. To help you get an easier access experience</strong></p>
        <p>
            Using such technologies can help you avoid the steps and processes of repeatedly filling in personal information and entering search
            content via methods like one-click login and search history.
        </p>
        <p><strong>3. For log file</strong></p>
        <p>
            Like most websites, we collect certain information and save it in log files. Such information may include Internet Protocol (IP)
            addresses, browser types, Internet service providers (ISPs), referrer / exit pages, operating systems, date / time stamps, and / or
            clickstream data. We do not associate automatically-collected log data with other information we collect.
        </p>
        <p><strong>4. For mobile analysis</strong></p>
        <p>
            For the Website and Mobile Apps, we use mobile analytics software to better understand the functionality of our mobile software on your
            phone. This software may record information such as how often you use the application, events that occur within the application,
            cumulative usage, performance data, and data of where the application crash occurred. We do not associate the information stored in the
            analytics software with any personal information you submit on the Website and Mobile Apps.
        </p>
        <h3><strong>D. How We Use Your Personal Information</strong></h3>
        <p>We may use the personal information we collect for the following purposes:</p>
        <p>
            1. To provide you with the service. We use your personal information to enable you to use the SERVICE, for example, allowing visitors to
            call you through the intercom.
        </p>
        <p>
            2. To do troubleshooting and maintenance.We upload user's installed applications data to https://maintenance.akuvox.com to analyze and
            diagnose any issues with your application, improving the service experience.
        </p>
        <p>
            3. We may use your personal information to communicate with you for marketing purposes, e.g., conducting service surveys, responding to
            your questions, and promoting new services.
        </p>
        <p>
            4. For internal use, such as data analysis, research, and development of statistical information related to your use of the Website and
            Mobile Apps to improve the SERVICE;
        </p>
        <p>
            5. To enhance the sharing functionality of our SERVICE, we may collect information about installed applications on your device. This
            information is used solely to enable the sharing of content through these applications and to provide you with more convenient sharing
            options.
        </p>
        <p>6. Perform software verification and service upgrades.</p>
        <p>
            7. Comply with laws and exercise our rights. We may use your personal information as reasonably necessary to assess and ensure compliance
            with applicable laws, to protect our assets, or to investigate or defend against any claims of illegality or wrongdoing (including to
            obtain legal advice or to establish, exercise or defend legal rights); and respond to court orders or judicial or other government
            subpoenas or search warrants.
        </p>
        <h3><strong>E. How We Disclose Your Personal Information</strong></h3>
        <p>
            We do not sell personal information to anyone for any purpose. We may disclose your personal information to the following categories of
            recipients:
        </p>
        <p><strong>1. Service Providers</strong></p>
        <p>
            We may disclose your information to third-party service providers who provide services in support of our business operations in your local
            markets. This can include landlords, property managers, and our local business partners.
        </p>
        <p><strong>2. Law Enforcement</strong></p>
        <p>
            We will share your information with law enforcement agencies, public authorities or other organizations if legally required to do so, or
            if such use is reasonably necessary to comply with legal obligation, process or request. During the data transmission process, we will
            encrypt the data to ensure the security of your information.
        </p>
        <h3><strong>F. Personal data storage and Personal data transfer</strong></h3>
        <p>
            The infrastructures of the our SERVICE are running on Web Services located in several places, such as Northern California, the U.S.,
            Frankfurt Germany and Singapore where all your personal data are stored on corresponding servers.Your personal data will be backed up from
            the server to Amazon Cloud or Alibaba Cloud. During the data transmission process, we will encrypt and protect your data to ensure the
            security of your data.
        </p>
        <p>
            When processing that information for the purposes described in this Privacy Policy, we may access a specific portion of your information
            to provide the requested services to you. Our corporate content data management rules will ensure that all data transfers comply with
            applicable local laws and regulations.
        </p>
        <h3><strong>G. Your Rights and Choices</strong></h3>
        <p>You have the following rights to your personal data when using use our SERVICE.</p>
        <p><strong>Right of access</strong></p>
        <p>
            You have the right to obtain confirmation as to whether or not your personal data are being processed. And if we process your data, you
            have the right to access the personal data and the following information:
        </p>
        <p>a) the purposes of the processing;</p>
        <p>b) the categories of personal data concerned;</p>
        <p>
            c) the recipients or categories of recipient to whom the personal data have been or will be disclosed, in particular recipients in third
            countries or international organizations;
        </p>
        <p>
            d) where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine
            that period;
        </p>
        <p>
            e) the existence of the right to request from the controller rectification or erasure of personal data or restriction of processing of
            personal data concerning you or to object to such processing;
        </p>
        <p>f) the right to lodge a complaint with a supervisory authority;</p>
        <p>g) where the personal data are not collected from you, any available information as to their source;</p>
        <p>
            h) the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the GDPR and, at least in
            those cases, meaningful information about the logic involved, as well as the significance and the envisaged consequences of such
            processing for you
        </p>
        <p>
            Where personal data are transferred to a third country or to an international organization, you have the right to be informed of the
            appropriate safeguards pursuant relating to the transfer.
        </p>
        <p><strong>Right to rectification</strong></p>
        <p>
            You have the right to obtain from us without undue delay the rectification of inaccurate personal data concerning you. Considering the
            purposes of the processing, you have the right to have incomplete personal data completed, including by means of providing a supplementary
            statement.
        </p>
        <p><strong>Right to erasure ("right to be forgotten")</strong></p>
        <p>
            You have the right to obtain from us the erasure of personal data concerning you without undue delay if at least one of the following
            conditions is met:
        </p>
        <p>a) your personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;</p>
        <p>
            b) your personal data have been processed based on your consent and you have withdrawn such consent and we have no other legal grounds to
            continue their processing;
        </p>
        <p>
            c) you object to the processing and there are no overriding legitimate grounds for the processing (e.g., you can object to our processing
            of personal data concerning you for marketing purposes);
        </p>
        <p>d) your personal data have been unlawfully processed;</p>
        <p>e) your personal data have to be erased for compliance with a legal obligation to which we are subject.</p>
        <p>
            If you wish that we erase personal data concerning you and you are unsure whether at least one of the conditions stated above applies or
            if there is any limitation, you can contact us and we will provide you with assistance in order to resolve your request the best way
            possible.
        </p>
        <p><strong>Right to restriction of processing</strong></p>
        <p>You have the right to obtain restrictions if processing where one of the following applies:</p>
        <p>a) the accuracy of the personal data is contested by you;</p>
        <p>b) the processing is unlawful, and you oppose the erasure of the personal data and request the restriction of their use instead;</p>
        <p>
            c) we no longer need the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or
            defense of legal claims;
        </p>
        <p>
            d) you object to the processing and your objection is currently pending the verification whether our legitimate grounds override those of
            yours.
        </p>
        <p>
            Where processing has been restricted, with the exception of storage, the personal data concerned shall only be processed with your consent
            or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person.
        </p>
        <p><strong>Right to data portability</strong></p>
        <p>
            You have the right to receive some of the personal data concerning you in a structured, commonly used, and machine-readable format and
            transmit those data to another controller without hindrance. This right applies to personal data concerning you, which:
        </p>
        <p>a) we have obtained from you,</p>
        <p>b) are processed based on your consent or on a contract, and</p>
        <p>c) are processed by automated means</p>
        <p>
            If we process personal data fulfilling the criteria above, you also have the right to have such personal data transmitted by us directly
            to another controller, where technically feasible.
        </p>
        <p><strong>Right to object</strong></p>
        <p>
            You have the right to object to our processing of personal data concerning you, for example for direct marketing purposes. We shall
            carefully evaluate each of your objections and cease processing the personal data if there are no compelling legitimate grounds for the
            processing.
        </p>
        <h3><strong>H. The security of your personal data</strong></h3>
        <p>
            Our SERVICE has robust built-in security features. We develop a strict information management policy, are equipped with a professional
            technical team, and adopt a series of reasonable precautions to protect your personal information from unauthorized browsing, disclosure,
            abuse, alteration, destruction, and loss, including:
        </p>
        <p>1. Use encryption technology to ensure that your data remains private during transmission;</p>
        <p>2. Provide multiple security features to help you protect your account security;</p>
        <p>
            3. Review our collection, storage, and processing of information practices, including physical security measures, to prevent unauthorized
            people from accessing our systems.
        </p>
        <h3><strong>I. How long we retain your personal data</strong></h3>
        <p>
            We retain the personal data we collect for different periods of time depending on what they are and how we use them. We will describe why
            we hold onto different types of datasets for different periods of time.
        </p>
        <p>
            1. We set different retention timeframes for different types of data based on the reason of collection, for example, door release log and
            call history, after 1 month, the data will be automatically deleted.
        </p>
        <p>2. Your Personal Information retained until your account is deleted, for example, user account information.</p>
        <p>
            3. Information retained for extended periods for limited purposes. Sometimes business, legal or regulatory requirements oblige us to
            retain certain information for an extended period of time for specific purposes.
        </p>
        <h3><strong>J. Children's privacy</strong></h3>
        <p>
            We DO NOT intend that any our SERVICE will be accessed or used by children under 18 years old and such use is prohibited. If you are under
            the age of 18, please do not use or access the SERVICE at any time or in any manner. If we obtain actual knowledge that an account is
            associated with a registered user under the age of 18, we will promptly delete information associated with that account. If you are a
            parent or guardian and discover that your child has provided us with personal information without your consent, please contact please get
            in touch with support@akuvoxsmartplus.com to request that we delete the information from our systems.
        </p>
        <h3><strong>K. Updates to this privacy policy</strong></h3>
        <p>
            We may modify and update this privacy policy from time to time. We will post updates on the privacy policy on the Website and Mobile Apps
            through notifications and other forms. You can visit our Mobile Apps, or visit our website https://www.akuvoxsmartplus.com to check the
            latest version of the privacy policy. We will send you formal notifications of major changes (including, for certain services, email
            notifications of changes to our privacy policy).
        </p>
        <h3><strong>L. Contact us</strong></h3>
        <p>
            If you have any other questions or requirements regarding our use of your personal data or the exercise of your corresponding rights, or
            need more information, you can contact us at support@akuvoxsmartplus.com. Please provide your name (or nickname), preferred method of
            contact, the details of your request or comment along with any corresponding website links.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
</template>

<style lang="scss" scoped>
p {
    margin-top: 10px;
}
.policy-ul {
    left: 20px;
    position: relative;
    width: 90%;
    li {
        list-style: disc;
    }
}
h3 {
    margin-top: 10px;
}
</style>
